<template>
	<div class="login-screen">
		<div class="container">
			<div class="alert alert-danger" role="alert" v-if="!!userInfo.errorMsg">{{ userInfo.errorMsg }}</div>
			<div class="auth-form-wrapper shadow-sm">
				<fieldset>
					<legend>Панель администратора</legend>
					<div class="form-text sub-title">Введите email и пароль для управления сайтом</div>
					<div class="mb-3">
						<label for="exampleInputEmail1" class="form-label">Email</label>
						<input type="email" class="form-control" aria-describedby="emailHelp" v-model="user.email" @keyup.enter="login()">
					</div>
					<div class="mb-3">
						<label for="exampleInputPassword1" class="form-label">Пароль</label>
						<input type="password" class="form-control" v-model="user.password" @keyup.enter="login()">
					</div>
					<!-- <div class="mb-3 form-check">
						<input type="checkbox" class="form-check-input" id="exampleCheck1">
						<label class="form-check-label" for="exampleCheck1">Запомнить меня</label>
					</div> -->
					<button type="submit" class="btn btn-primary btn-block" @click="login()" :disabled="userInfo.loginInProcess">Войти</button>
				</fieldset>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            user: {
                email: '',
                password: ''
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters['getUserInfo'];
        }
    },
    methods: {
        login() {
            if (this.user.email.length && this.user.password.length) {
                this.$store.dispatch('login', this.user);
            }
        }
    },
    mounted() {
        
    }
}
</script>
