import Vue from 'vue'
import Vuex from 'vuex'
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";

Vue.use(Vuex)

const app = initializeApp({
    apiKey: "AIzaSyB_FPARAK3iq2l6aydh7bIlgrdjZzP7uN0",
    authDomain: "csf-clinic.firebaseapp.com",
    projectId: "csf-clinic",
    storageBucket: "csf-clinic.appspot.com",
    messagingSenderId: "981127696406",
    appId: "1:981127696406:web:9c6473a7c3cec950c4e537"
});

const DB = getFirestore(app);
const AUTH = getAuth();

// Controllers
function getColectionFromDatabase(c) {
	return getDocs(collection(DB, c));
}
function getDocFromDatabase(c, d) {
	return getDoc(doc(DB, c, d));
}
function setLs(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}
// function getLs(key) {
// 	return JSON.parse(localStorage.getItem(key));
// }
function removeFromLs(key) {
	localStorage.removeItem(key);
}

export default new Vuex.Store({
    namespaced: true,
	state: {
		categories: [],
		services: [],
		isCatalogLoading: true,
        metaInfo: {},
		staticPageContent: {
			home: '',
			about: '',
            services: '',
			contacts: '',
            sales: '',
            team: '',
            reviews: '',
			generalInfo: ''
		},
		user: {
			loginInProcess: false,
			isLoggedIn: false,
			errorMsg: ''
		},
        orders: []
	},
	getters: {
        getOrders(state) {
            return state.orders;
        },
        getMetaInfo(state) {
            return state.metaInfo;
        },
		getCategories(state) {
			return state.categories;
		},
		getServices(state) {
			return state.services;
		},
		getHomepageContent(state) {
			return state.staticPageContent.home;
		},
		getAboutpageContent(state) {
			return state.staticPageContent.about;
		},
		getTeampageContent(state) {
			return state.staticPageContent.team;
		},
		getServicespageContent(state) {
			return state.staticPageContent.services;
		},
		getReviewspageContent(state) {
			return state.staticPageContent.reviews;
		},
		getSalespageContent(state) {
			return state.staticPageContent.sales;
		},
		getContactspageContent(state) {
			return state.staticPageContent.contacts;
		},
		getGeneralInfo(state) {
			return state.staticPageContent.generalInfo;
		},
		getUserInfo(state) {
			return state.user;
		},
		getOptionById: (state) => (id) => {
			for (let i = 0; i < state.categories.length; i++) {
				for (let j = 0; j < state.categories[i].list.length; j++) {
					if (state.categories[i].list[j].id == id) {
						return JSON.parse(JSON.stringify(state.categories[i].list[j]));
					}
				} 
			}
		}
	},
	mutations: {

	},
	actions: {
		login(context, user) {
			context.state.user.loginInProcess = true;
			signInWithEmailAndPassword(AUTH, user.email, user.password)
				.then(() => {
					context.state.user.isLoggedIn = true;
					context.state.user.loginInProcess = false;
					setLs('user', user);
				})
				.catch((error) => {
					context.state.user.errorMsg = error.message;
					context.state.user.loginInProcess = false;
				})
		},
		logout(context) {
			signOut(AUTH).then(() => {
				context.state.user.isLoggedIn = false;
				removeFromLs('user');
			})
		},
		fetchCategories(context) {
			getColectionFromDatabase('Categories').then((data) => {
				data.forEach((element) => {
					context.state.categories.push(element.data());
				})
			});
		},
		fetchServices(context) {
			getColectionFromDatabase('Services').then((data) => {
                context.state.services = [];
				data.forEach((element) => {
					context.state.isCatalogLoading = false;
					context.state.services.push(element.data());
				})
			});
		},
        fetchOrders(context) {
			getColectionFromDatabase('Orders').then((data) => {
				data.forEach((element) => {
					context.state.orders.push(element.data());
				})
			});
		},
		fetchStaticContent(context) {
			getDocFromDatabase('Pages', 'Home').then((data) => {
				context.state.staticPageContent.home = data.data();
			});
			getDocFromDatabase('Pages', 'Services').then((data) => {
				context.state.staticPageContent.services = data.data();
			});
			getDocFromDatabase('Pages', 'About').then((data) => {
				context.state.staticPageContent.about = data.data();
			});
			getDocFromDatabase('Pages', 'Team').then((data) => {
				context.state.staticPageContent.team = data.data();
			});
			getDocFromDatabase('Pages', 'Contacts').then((data) => {
				context.state.staticPageContent.contacts = data.data();
			});
			getDocFromDatabase('Pages', 'Sales').then((data) => {
				context.state.staticPageContent.sales = data.data();
			});
			getDocFromDatabase('Pages', 'Reviews').then((data) => {
				context.state.staticPageContent.reviews = data.data();
			});
			getDocFromDatabase('Pages', 'GeneralInfo').then((data) => {
				context.state.staticPageContent.generalInfo = data.data();
			});
		},
        fetchMetaInfo(context) {
            getDocFromDatabase('SEO', 'Meta').then((data) => {
				context.state.metaInfo = data.data();
			});
        },
		saveCategories(context, categories) {
			var list = [];
			categories.forEach((category) => {
				list.push(setDoc(doc(DB, 'Categories', category.id), category));
			});
			return Promise.all(list);
		},
		saveHomePage(context, pageContent) {
            return setDoc(doc(DB, 'Pages', 'Home'), pageContent);
		},
		saveAboutPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'About'), pageContent);
		},
		saveTeamPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Team'), pageContent);
		},
		saveServicesPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Services'), pageContent);
		},
		saveSalesPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Sales'), pageContent);
		},
		saveReviewsPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Reviews'), pageContent);
		},
		saveGeneralInfo(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'GeneralInfo'), pageContent);
		},
		saveContactsPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Contacts'), pageContent);
		},
		saveSeoPage(context, pageContent) {
            return setDoc(doc(DB, 'SEO', 'Meta'), pageContent)
		},
		saveServicePage(context, product) {
            return setDoc(doc(DB, 'Services', product.code), product)
		},
        saveOrdersPage(context, orders) {
			var list = [];
			orders.forEach((order) => {
				list.push(setDoc(doc(DB, 'Orders', order.docName), order));
			});
			return Promise.all(list);
		}
	}
})
