<template>
    <div class="app-header">
		<nav class="navbar navbar-expand-lg navbar-dark">
			<div class="container-fluid">
				<router-link class="navbar-brand" to="/">Admin Panel <small>(CSF-Clinic)</small></router-link>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
						<router-link class="nav-link" active-class="active" to="/">Заявки</router-link>
					</li>
					<li class="nav-item dropdown">
						<router-link class="nav-link" active-class="active" to="/services">Услуги</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" active-class="active" to="/categories">Категории</router-link>
					</li>
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Страницы</a>
						<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
							<li><router-link class="dropdown-item" to="/pages/home">Главная</router-link></li>
							<li><router-link class="dropdown-item" to="/pages/about">О нас</router-link></li>
							<li><router-link class="dropdown-item" to="/pages/services">Услуги</router-link></li>
							<li><router-link class="dropdown-item" to="/pages/team">Команда</router-link></li>
                            <li><router-link class="dropdown-item" to="/pages/sales">Акции</router-link></li>
							<li><router-link class="dropdown-item" to="/pages/reviews">Отзывы</router-link></li>
							<li><router-link class="dropdown-item" to="/pages/contacts">Контакты</router-link></li>
                            <li><router-link class="dropdown-item" to="/pages/general">Общая информация</router-link></li>
						</ul>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" active-class="active" to="/seo">SEO</router-link>
					</li>
				</ul>
				<div class="actions d-flex">
					<button class="btn btn-danger" @click="logout()">Выйти</button>
				</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
export default {
    methods: {
        logout() {
            this.$store.dispatch('logout');
        }
    }
}
</script>
